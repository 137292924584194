/* eslint-disable react/jsx-no-useless-fragment */
import React from "react";
import classNames from "classnames";

const InputText = ({
  name,
  placeholder,
  required,
  formState,
  hasError,
  options,
  className: _className,
}) => {
  const [form, setForm] = formState;

  return (
    <select
      name={name}
      id={name}
      className={classNames(
        "w-full max-w-full border border-gray p-0.5",
        _className,
        {
          "!border-green": hasError,
        }
      )}
      onChange={e => {
        setForm({ ...form, [name]: e.target.value });
      }}
    >
      <>
        {placeholder && (
          <option key="placeholder" value="">
            {placeholder}
          </option>
        )}
        {options?.map((option, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <option key={i} value={option}>
              {option}
            </option>
          );
        })}
      </>
    </select>
  );
};

InputText.defaultProps = {};

export default InputText;
