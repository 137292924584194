import React from "react";
import classNames from "classnames";

const Toggle = ({
  id,
  name,
  value,
  label,
  checked,
  onClick,
  onChange,
  className: _className,
}) => {
  return (
    <div className="flex items-center justify-center">
      <label htmlFor={id} className="relative">
        <input
          type="checkbox"
          name={name}
          id={id || name}
          value={value}
          className="peer sr-only"
          checked={checked}
          onClick={onClick}
          onChange={onChange}
        />
        <div
          className={classNames(
            "peer cursor-pointer rounded-none border-2 border-black bg-white px-3 py-2 text-lg duration-300 hover:rounded-[2rem] peer-checked:rounded-[2rem] peer-checked:border-green peer-checked:bg-green xl:text-base",
            _className
          )}
        >
          {label}
        </div>
      </label>
    </div>
  );
};

Toggle.defaultProps = {};

export default Toggle;
