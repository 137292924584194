import React from "react";
import propTypes from "prop-types";

const BrowserWarning = ({ display }) => {
  let outOfDate = false;
  if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    const supportedBrowsers = require("../../../supportedBrowsers");
    if (!supportedBrowsers.test(navigator.userAgent)) {
      outOfDate = true;
    }
  }

  // display if overriden or browser is outofDate
  if (outOfDate || display) {
    return (
      <div className="z-60 fixed bottom-0 left-0 right-0 flex justify-center p-6 font-sans font-bold uppercase text-white xl:p-12">
        <div className="bg-white p-1">
          <div className="border-white bg-green p-6 text-center leading-none shadow xl:p-12">
            For the best experience, please make sure your browser is{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://browser-update.org/update.html"
              className="underline-2"
            >
              up-to-date
            </a>{" "}
            and javascript is enabled.
          </div>
        </div>
      </div>
    );
  }
  return null;
};

BrowserWarning.defaultProps = {
  display: false,
};

BrowserWarning.propTypes = {
  display: propTypes.bool,
};

export default BrowserWarning;
