import React, { useEffect } from "react";
import propTypes from "prop-types";
import { LazyMotion } from "framer-motion";
import loadable from "@loadable/component";
import { useAppState } from "@state";

import BrowserWarning from "../atoms/BrowserWarning";

const Header = loadable(() => import("@organisms/Header"));
const GdprBanner = loadable(() => import("@organisms/GdprBanner"));
const Announcement = loadable(() => import("@organisms/Announcement"));
const Modal = loadable(() => import("../molecules/Modal"));

const createPromise = () => {
  let args;
  // eslint-disable-next-line no-return-assign, no-promise-executor-return
  const promise = new Promise((..._) => (args = _));
  return [promise, ...args];
};

const [promise, resolve] = createPromise();
const loadFeatures = () => promise;

const DefaultLayout = ({ children, path, pageContext }) => {
  // load animation features
  useEffect(() => {
    resolve(import("@utils/animationFeatures").then(r => r.domMax));
  }, []);

  const [, dispatch] = useAppState();

  // set lang
  useEffect(() => {
    dispatch({ type: "setLang", lang: pageContext?.lang || "en" });
  }, []);

  // the layout
  return (
    <LazyMotion features={loadFeatures}>
      <div className="flex min-h-screen flex-col">
        <Announcement />
        {/* <PageSizeObserver /> */}
        <Header locales={pageContext?.locales} pageLang={pageContext?.lang} />
        {children}
        <BrowserWarning />
        <GdprBanner />
        {/* uncomment if embedding facebook posts */}
        {/* <div id="fb-root" /> */}
        <Modal />
      </div>
    </LazyMotion>
  );
};

DefaultLayout.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
};

export default DefaultLayout;
