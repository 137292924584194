import layout from "./reducers/layout";
import modal from "./reducers/modal";
import translation from "./reducers/translation";

import createAppState from "./lib/createAppState";

const reducer = {
  layout: layout.reducer,
  modal: modal.reducer,
  translation: translation.reducer,
};

const initialState = {
  layout: layout.initialState,
  modal: modal.initialState,
  translation: translation.initialState,
};

export const { AppStateProvider, AppStateConsumer, useAppState } =
  createAppState(reducer, initialState);

export default {
  AppStateProvider,
  AppStateConsumer,
  useAppState,
};
