import React, { useState } from "react";
import classNames from "classnames";
import {
  InputText,
  InputTextarea,
  InputCheckbox,
  InputRadio,
  InputSelect,
} from "@base";
import Text from "./Text";

const Input = ({
  color,
  label,
  hideLabel,
  type,
  placeholder,
  name,
  singleLine,
  formState,
  errorState,
  errorMessage,
  className: _className,
  value,
  required,
  options,
  id,
  group,
  groupName,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [form, setForm] = formState || useState({});
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [error] = errorState || useState([]);
  const hasError = error?.includes(name);
  const [checkedList, setCheckedList] = useState([]);

  let Field;
  switch (type) {
    case "text":
      Field = InputText;
      break;
    case "textarea":
      Field = InputTextarea;
      break;
    case "checkbox":
      Field = InputCheckbox;
      break;
    case "radio":
      Field = InputRadio;
      break;
    case "select":
      Field = InputSelect;
      break;
    default:
      Field = InputText;
      break;
  }

  // Only have the group work with checkboxes and radio buttons
  // If wanting to have multiple <Input/>s, use a <FieldSet /> as a container:
  // <FieldSet>
  //   <Input>
  //   <Input>
  //   <Input>
  // </FieldSet>
  if (group?.length && (type === "checkbox" || type === "radio")) {
    return (
      <fieldset className={classNames(_className, { "text-green": hasError })}>
        {label && (
          <legend className={classNames("mb-2", { "sr-only": hideLabel })}>
            <span className="flex">
              {label} {required && <span className="text-green">*</span>}
            </span>
          </legend>
        )}
        {group?.map((_g, i) => {
          return (
            <label
              htmlFor={_g?.id || name}
              key={_g.id}
              className={classNames({
                "mb-2 flex items-center": singleLine || _g?.singleLine,
                "mb-2 flex flex-row-reverse items-center justify-end":
                  type === "checkbox" || type === "radio",
              })}
            >
              <span
                className={classNames("block", {
                  "mb-2":
                    !singleLine && type !== "checkbox" && type !== "radio",
                  "ml-2 text-sm": type === "checkbox" || type === "radio",
                  "mr-2": singleLine || _g?.singleLine,
                })}
              >
                {_g?.label}
              </span>
              <Field
                {..._g}
                groupName={groupName}
                color={color}
                id={_g?.id || name}
                name={_g?.name || name}
                value={_g.value}
                formState={[form, setForm]}
                hasError={hasError}
                groupState={
                  type === "checkbox" ? [checkedList, setCheckedList] : null
                }
                className=""
              />
            </label>
          );
        })}
        {errorMessage && hasError && (
          <Text variant="xs" className="mt-1 text-green">
            {errorMessage}
          </Text>
        )}
      </fieldset>
    );
  }

  return (
    <div className={classNames(_className)}>
      <div
        className={classNames("relative", {
          "flex items-center": singleLine,
          "flex flex-row-reverse items-center justify-end":
            type === "checkbox" || type === "radio",
          "text-green": hasError,
        })}
      >
        <label
          htmlFor={id || name}
          className={classNames("block", {
            "mb-2": !singleLine && type !== "checkbox" && type !== "radio",
            "mr-2": singleLine,
            "ml-2": type === "checkbox" || type === "radio",
            "sr-only": hideLabel,
          })}
        >
          <span className="flex">
            {label} {required && <span className="text-green">*</span>}
          </span>
        </label>

        <Field
          color={color}
          name={name}
          id={id || name}
          value={value}
          placeholder={placeholder}
          formState={[form, setForm]}
          hasError={hasError}
          options={options}
          className=""
        />
      </div>
      {errorMessage && hasError && (
        <Text variant="xs" className="mt-1 text-green">
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

Input.defaultProps = {};

export default Input;
