import React from "react";
import classNames from "classnames";
import { AppLink } from "@base";
import Icon from "./Icon";

const ButtonAlt = ({
  children,
  className: _className,
  down,
  icon,
  onClick,
  reverse,
  to,
}) => {
  return (
    <AppLink
      to={to}
      onClick={onClick}
      className={classNames("group inline-flex items-center gap-2", _className)}
    >
      {reverse && icon && (
        <Icon
          name="arrow"
          className={classNames(
            "h-auto w-4 rotate-180 duration-300 group-hover:text-green",
            {
              "group-hover:-translate-x-2": !down,
              "rotate-90 group-hover:translate-y-2": down,
            }
          )}
        />
      )}
      {/* label */}
      <span className="text-base font-bold uppercase leading-normal decoration-inherit duration-300 group-hover:text-green group-hover:decoration-green">
        {children}
      </span>
      {!reverse && icon && (
        <Icon
          name="arrow"
          className={classNames(
            "h-auto w-4 duration-300 group-hover:text-green",
            {
              "group-hover:translate-x-2": !down,
              "rotate-90 group-hover:translate-y-2": down,
            }
          )}
        />
      )}
    </AppLink>
  );
};

ButtonAlt.defaultProps = {
  down: false,
  reverse: false,
  icon: true,
};

export default ButtonAlt;
