import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { m, useAnimation } from "framer-motion";
import Icon from "./Icon";

// todo: add documentation

const Dropdown = ({
  model,
  options,
  placeholder,
  inverse,
  size,
  sans,
  id,
  label,
}) => {
  const [isActive, setIsActive] = useState(false);
  const toggleActive = () => {
    setIsActive(!isActive);
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [value, setValue] = model || useState();
  const dropdown = useRef();
  const dropdownControl = useAnimation();

  // close menu if it's open and you click outside of it
  const handleClickOutside = event => {
    if (dropdown.current && !dropdown.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  // change state and close menu when a dropdown item is selected
  useEffect(() => {
    setIsActive(false);
  }, [value]);

  // animations
  useEffect(() => {
    if (isActive) {
      dropdownControl.start({
        height: "auto",
        opacity: 1,
        display: "block",
        transition: {
          type: "tween",
          duration: 0.25,
          ease: "easeIn",
        },
      });
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("touchstart", handleClickOutside);
    }
    if (!isActive) {
      dropdownControl.start({
        height: 0,
        opacity: 0,
        transition: {
          type: "tween",
          duration: 0.25,
          ease: "easeIn",
        },
        transitionEnd: {
          display: "hidden",
        },
      });
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    }
  }, [isActive]);

  // eslint-disable-next-line global-require

  let textSize;
  let iconSize;

  switch (size) {
    case "xs":
      textSize = "text-sm xl:text-xs";
      iconSize = "w-2 h-2";
      break;
    case "sm":
      textSize = "text-base xl:text-sm";
      iconSize = "w-3 h-3";
      break;
    case "md":
      textSize = "text-lg xl:text-base";
      iconSize = "w-3 h-3";
      break;
    case "lg":
      textSize = "text-2xl xl:text-lg";
      iconSize = "w-4 h-4";
      break;
    default:
      textSize = "text-lg xl:text-base";
      iconSize = "w-3 h-3";
      break;
  }

  return (
    <div
      className={classNames("relative", {
        "font-sans text-sm font-bold uppercase": sans,
        "font-serif": !sans,
      })}
    >
      <m.button
        type="button"
        ref={dropdown}
        onTap={toggleActive}
        className={classNames(
          "flex items-center justify-between",
          {
            "px-3 py-1": size === "xs",
            "px-4 py-2": size !== "xs",
            "bg-black text-white": inverse,
            "bg-white text-black": !inverse,
          },
          textSize
        )}
        aria-expanded={isActive}
        aria-controls={id}
        aria-haspopup="listbox"
      >
        <span className="mr-2 whitespace-nowrap text-left font-bold">
          {value != null && value !== ""
            ? options?.[value]?.title
            : placeholder}
        </span>
        {/* dropdown Icon */}
        <Icon
          name="chevron"
          className={classNames(
            "icon transform text-black duration-300",
            {
              "rotate-90": isActive,
              "rotate-0": !isActive,
            },
            iconSize
          )}
        />
      </m.button>
      <m.ul
        initial={{ height: 0, opacity: 0 }}
        animate={dropdownControl}
        className={classNames(
          "absolute left-0 z-40 overflow-hidden border-b-2 border-l-2 border-r-2",
          {
            "border-green bg-black text-white": inverse,
            "border-black bg-white text-black": !inverse,
            "shadow-xl": isActive,
            "shadow-none": !isActive,
          },
          textSize
        )}
        id={id}
        role="listbox"
        aria-label={label || placeholder || `dropdown--${id}`}
        tabIndex="-1"
      >
        {placeholder.length > 1 && (
          <m.li
            role="option"
            aria-selected={false}
            className={classNames(
              "cursor-pointer whitespace-nowrap px-4 py-2 text-left opacity-50",
              textSize
            )}
            disabled
          >
            {placeholder}
          </m.li>
        )}
        {options?.map((option, i) => {
          return (
            <m.li
              role="option"
              aria-selected={value === option.uid}
              key={option.uid || option.value}
              onTapStart={() => setValue(i)}
              className="cursor-pointer whitespace-nowrap px-4 py-2 text-left transition duration-300 hover:bg-green focus:bg-green"
              tabIndex={isActive ? "0" : "-1"}
            >
              {option.title}
            </m.li>
          );
        })}
      </m.ul>
    </div>
  );
};

Dropdown.defaultProps = {
  placeholder: "Select One",
  inverse: false,
  size: "md",
  sans: false,
  id: "dropdown",
};

export default Dropdown;
