import React from "react";
import classNames from "classnames";

const Text = ({
  children,
  className: _className,
  variant,
  richText,
  quotes,
  tag,
}) => {
  let Tag = tag;
  let classes = "text-lg xl:text-base";

  switch (variant) {
    case "h1":
      Tag = "h1";
      classes = "font-sans text-5xl md:text-6xl leading-tighter font-bold";
      break;
    case "h2":
      Tag = "h2";
      classes = "font-sans text-4xl md:text-5xl leading-tighter font-bold";
      break;
    case "h3":
      Tag = "h3";
      classes = "font-sans text-3xl md:text-4xl leading-tighter font-bold";
      break;
    case "h4":
      Tag = "h4";
      classes = "font-sans text-2xl md:text-3xl leading-tighter font-bold";
      break;
    case "h5":
      Tag = "h5";
      classes = "font-sans text-xl md:text-2xl leading-tighter font-bold";
      break;
    case "h6":
      Tag = "h6";
      classes = "font-sans text-lg md:text-xl leading-tighter font-bold";
      break;
    case "h7":
      Tag = "h6";
      classes = "font-sans text-lg leading-tighter font-bold";
      break;
    case "heading":
      Tag = "h1";
      classes =
        "font-heading uppercase leading-[0.625] tracking-normal text-[6rem] sm:text-[8rem] md:text-[10rem]";
      break;
    case "headingSm":
      Tag = "h2";
      classes =
        "font-heading uppercase leading-[0.625] tracking-normal text-[4rem] sm:text-[6rem] md:text-[8.25rem]";
      break;
    case "xl":
      classes = "block text-2xl leading-normal font-sans";
      break;
    case "lg":
      classes = "block text-xl leading-normal font-sans";
      break;
    case "body":
      classes = "block text-lg xl:text-base leading-normal font-sans";
      break;
    case "sm":
      classes = "block text-base xl:text-sm font-sans leading-normal";
      break;
    case "xs":
      classes = "block text-sm xl:text-xs font-sans leading-tight";
      break;
    case "label":
      classes =
        "block text-base leading-normal font-bold font-sans uppercase tracking-0.5";
      break;
    case "link":
      classes =
        "text-base xl:text-sm underline decoration-transparent decoration-2 underline-offset-4 duration-300";
      break;
    case "linkSm":
      classes =
        "text-sm xl:text-xs underline decoration-transparent decoration-2 underline-offset-4 duration-300";
      break;

    default:
      classes = "";
      break;
  }

  // Now using Tailwind's Typography plugin but since
  // Craft Redactor is usually returning string, it'll render those out.
  // Styling can still be handled with prose and no need
  // for the `richText` utility classes
  // docs: https://tailwindcss.com/docs/typography-plugin
  return React.Children.toArray(children).map((child, i) => {
    if (typeof child.type === "undefined") {
      return (
        <Tag
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={classNames(classes, _className, {
            quotes,
            "prose prose-sm xl:prose-xl": richText,
          })}
          dangerouslySetInnerHTML={{ __html: child }}
        />
      );
    }
    // otherwise return children
    return (
      <Tag
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        className={classNames(classes, _className, {
          quotes,
          "prose prose-sm xl:prose-xl": richText,
        })}
      >
        {child}
      </Tag>
    );
  });
};

Text.defaultProps = {
  tag: "span",
  className: null,
  variant: null,
  richText: false,
  quotes: false,
  children: null,
  dewidow: false,
};

export default Text;
