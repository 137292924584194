// eslint-disable-next-line import/prefer-default-export
export const buildCloudflareURL = ({
  url,
  w,
  h,
  q = "80",
  f = "auto",
  dpr = 1.33,
  g = "center",
  fit = "cover",
}) => {
  const params = {
    w,
    h,
    q,
    f,
    dpr,
    g,
    fit,
  };
  const paramsString = Object.entries(params)
    .map(([k, v]) => `${k}=${v}`)
    .join(",");
  const newSrc = `${
    process.env.GATSBY_PUBLIC_ASSET_BASE_URL
  }/cdn-cgi/image/${paramsString}${url.replace(
    process.env.GATSBY_PUBLIC_ASSET_BASE_URL,
    ""
  )}`;

  return newSrc;
};
