import React from "react";
import classNames from "classnames";

const InputText = ({
  color,
  name,
  placeholder,
  required,
  formState,
  hasError,
  id,
  className: _className,
}) => {
  const [form, setForm] = formState;

  return (
    <input
      type="text"
      name={name}
      id={id || name}
      placeholder={placeholder}
      className={classNames(
        "w-full max-w-full appearance-none border-b-2 bg-transparent pb-2",
        _className,
        {
          "border-white": color === "white",
          "border-black": color === "black",
          "!border-green": hasError,
        }
      )}
      onChange={e => {
        setForm({ ...form, [name]: e.target.value });
      }}
    />
  );
};

InputText.defaultProps = {
  color: "white",
};

export default InputText;
