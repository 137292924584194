import React from "react";
import { AppLink } from "@base";
import classNames from "classnames";
import Icon from "./Icon";

const Button = ({
  color,
  className: _className,
  to,
  title,
  type,
  onClick,
  linkObj: LinkObj,
  children,
  rotate,
  download,
}) => {
  const classes = classNames(
    "leading-tight group relative z-0 flex cursor-pointer items-center font-bold uppercase tracking-wide transition duration-300 text-base xl:text-sm",
    {
      "inline-flex": !rotate,
      "flex-col": rotate,
    },
    _className
  );

  const textClasses = classNames(
    "relative z-10 tracking-1 duration-300 text-left",
    {
      "text-black": color === "black" || color === "green",
      "text-white": color === "white",
      "[writing-mode:vertical-lr]": rotate,
    }
  );

  const wrapperClasses = classNames(
    "flex aspect-square items-center justify-center rounded-full p-2 duration-300",
    {
      "ml-2": !rotate,
      "mt-2 rotate-90": rotate,
      "group-hover:translate-x-2": !rotate && !download,
      "group-hover:translate-y-2": rotate || download,
      "bg-black group-hover:bg-white": color === "black",
      "bg-green group-hover:bg-white": color === "white",
      "bg-green group-hover:bg-black": color === "green",
    }
  );

  const iconClasses = classNames("h-auto w-4 duration-300", {
    "text-white group-hover:text-black": color === "black",
    "text-black": color === "white",
    "text-black group-hover:text-white": color === "green",
    "rotate-90": download,
  });

  if (to && to.length > 1) {
    return (
      <LinkObj
        to={to}
        title={title}
        className={classes}
        onClick={onClick}
        download={download}
      >
        <span className={textClasses}>{children}</span>
        <div className={wrapperClasses}>
          <Icon name="arrow" className={iconClasses} />
        </div>
      </LinkObj>
    );
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classes}
      onClick={onClick}
    >
      <span className={textClasses}>{children}</span>
      <div className={wrapperClasses}>
        <Icon name="arrow" className={iconClasses} />
      </div>
    </button>
  );
};

Button.defaultProps = {
  linkObj: AppLink,
  color: "green",
  title: null,
  type: "button",
  onClick: null,
  size: "sm",
  to: null,
  download: false,
};

export default Button;
